<template>
  <div class="flex h-screen">
    <slot />
  </div>
</template>
<script>
export default {
  name: "DefaultLayout"
};
</script>
